import React from 'react';
import './Footer.css'; // Optional: Add styles for the footer

const Footer = () => (
  <footer>
    <p>&copy; {new Date().getFullYear()} Eklara. All rights reserved.</p>
  </footer>
);

export default Footer;
