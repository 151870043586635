import React, { useState, useEffect } from "react";

const CounterSection = () => {
  const counters = [
    { id: 1, label: "Drivers Connected", count: 50, format: "+" },
    { id: 2, label: "Spare Parts Delivered", count: 100, format: "+" },
    { id: 3, label: "Services Offered", count: 10, format: "+" },
    { id: 4, label: "Support Availability", count: 24/7, format: "+" },
  ];

  return (
    <div style={{ padding: "10px 0", textAlign: "left", background: "#fff" }}>
     
        <div className="row">
          {counters.map((counter) => (
            <div key={counter.id} className="col-lg-3 col-md-6 mb-4">
              <AnimatedCounter target={counter.count} format={counter.format} />
              <p
                style={{
                  fontSize: "0.7rem",
                  fontWeight: "700",
                  color: "#777",
                }}
              >
                {counter.label}
              </p>
            </div>
          ))}
        </div>
     
    </div>
  );
};

const AnimatedCounter = ({ target, format }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    let end = target;
    let increment = end / 600;

    const timer = setInterval(() => {
      start += increment;
      if (start >= end) {
        start = end;
        clearInterval(timer);
      }
      setCount(start);
    }, 10);

    return () => clearInterval(timer);
  }, [target]);

  const formatCount = (value) => {
    return `${Math.floor(value)}\u00A0${format}`;
  };

  return (
    <div style={{ fontSize: "2rem", fontWeight: "bold", color: "#333" }}>
      {formatCount(count)}
    </div>
  );
};

export default CounterSection;
