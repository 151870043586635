import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const TestimonialSlider = () => {
  const testimonials = [
    {
      id: 1,
      name: "John Doe",
      content: "This is an amazing service! It has significantly improved my business.",
      position: "CEO, Company"
    },
    {
      id: 2,
      name: "Jane Smith",
      content: "Highly recommend to everyone. The customer support is fantastic!",
      position: "Manager, Another Company"
    },
    {
      id: 3,
      name: "Michael Johnson",
      content: "Best experience I've ever had. The team is highly professional.",
      position: "Director, Some Company"
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Desktop view me 2 cards show karne ke liye
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // 768px se chhote screens ke liye settings
        settings: {
          slidesToShow: 1, // Mobile view me 1 card show karne ke liye
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {testimonials.map((testimonial) => (
      
        <div key={testimonial.id}>
          <div style={{ padding: "20px", textAlign: "center", margin: "15px", border: "1px solid #ddd", borderRadius: "10px" }}>
            <h6>Testimonial</h6> {/* Heading added */}
            <p style={{ fontSize: "16px" }}>{testimonial.content}</p> {/* Dummy content */}
            <h3 style={{ marginTop: "15px" }}>{testimonial.name}</h3> {/* Testimonial name */}
            <p>{testimonial.position}</p> {/* Testimonial position */}
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default TestimonialSlider;
