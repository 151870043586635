import React from "react";
import Header from "../components/common/Header"; // Adjusted path
import Footer from "../components/common/Footer"; // Adjusted path
import FeaturesSection from '../components/common/FeaturesSection';
import "./Home.css"; // Add custom styles for the home page
import faqBackground from "../assets/images/faq2-bg.webp"; // Adjust the path as needed
import ContactForm from "../components/common/ContactForm";
import TestimonialSlider from '../components/common/TestimonialSlider';
import CounterSection from '../components/common/CounterSection';
import ServicePriceList from '../components/common/ServicePriceList';  // Adjust the path if necessary
import EServicesHelpdesk from '../components/common/EServicesHelpdesk';

const Home = () => (
  <div>
    <Header />
    <div
      className="faq-area-2"
      style={{ backgroundImage: `url(${faqBackground})` }}
    >
      <div className="container">
        <div className="row faq-content-wrap2 space">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 my-auto text-white fw-bold">
            <div className="title-area mb-45">
             
              <h2 className="sec-title  py-2 fw-bold">
              Get your Auto Road-Ready <br />  with Eklara!
              </h2>
              <h6 className="py-4 pe-md-5 me-md-5">
              Find everything your auto needs; Spare Parts, Services, and Support; <br/> all in one place — fast, easy, and affordable.
              </h6>
              <h5 className="caveat-text fw-bold">Let’s Get you Moving!</h5>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 contact-form-wrap my-5">
            <div className="title-area text-center">
              <h5 className="sec-title text-white">
                Experience the Best Auto Service
              </h5>
            </div>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
    <section className="services text-center pt-5">
      <div className="container">
        <div className="row">
          <div className="col-8 pe-md-5 ps-0">
            <div class="section-head text-lg-start text-center style-5 pt-1 mt-4 "
            >  <h2 className="text-lg-start text-center">Explore Eklara Shop</h2>
             </div>
            <FeaturesSection />
            <EServicesHelpdesk />
            <div className="py-5">
            <h2 className="text-lg-start text-center">What Car Owners In Delhi Say</h2>
            <TestimonialSlider />
            </div>
            <div className="py-5">
            <h2 className="text-lg-start text-center">Become an Eklara's Premium Member</h2>
            <div className="premium-container">
      <div className="step">
        <div className="number">1</div>
        <div className="content">
          <h3>🚗 On Road Assistance</h3>
          <p>Available 24*7 for your quick help whenever you’re stuck on the road.</p>
        </div>
      </div>

      <div className="step">
        <div className="number">2</div>
        <div className="content">
          <h3>📞 On Call Legal Assistance</h3>
          <p>Get expert advice and support when needed, and ensure you're always protected.</p>
        </div>
      </div>

      <div className="step">
        <div className="number">3</div>
        <div className="content">
          <h3>💼 Earn Side Income</h3>
          <p>Tap into additional income streams through delivery services and advertising opportunities.</p>
        </div>
      </div>

      <div className="step">
        <div className="number">4</div>
        <div className="content">
          <h3>💸 Instant Micro Loan Disbursal</h3>
          <p>Fast access to funds to handle unexpected expenses without breaking a sweat.</p>
        </div>
      </div>

      <button className="eligibility-btn">Check your Eligibility</button>
           </div>
            </div>
            <div className="py-3 text-lg-start text-center">
            <h2 className="text-lg-start text-center">Eklara Guarantee</h2>
            <div className=" row row-cols-1 row-cols-md-2 g-4">
              <div className="col">
                <h5 className="box-1 p-3">Quality Spare Parts</h5>
              </div>
              <div className="col">
               <h5 className="box-2 p-3"> Exclusive Member Perks</h5>
              </div>
              <div className="col">
               <h5 className="box-3 p-3">Instant Paperwork Processing</h5>
              </div>
              <div className="col">
                <h5 className="box-4 p-3"> 24/7 Customer Support.</h5>
              </div>
            </div>
            </div>
            <div className="py-3">
            <CounterSection />
            </div>
           
            <div className="py-2">
           
              <div className="eklara-container">
      <h1 className="caveat-text fw-bold fs-3 text-lg-start">Together, we are</h1>
      <h2 className="text-lg-start">Auto Rickshaw Drivers' Service Committee</h2>

      <p className="intro-text">
        Welcome to the Eklara Committee - where we come together to drive change and provide top-notch service to our drivers. Join us as we navigate the roads of success and steer towards a brighter future for all.
      </p>

      <h3 className="text-lg-start text-center mb-3">Why Choose Eklara Autos?</h3>

      <ul className="benefits-list">
        <li> <strong>All-in-One Solution:</strong><br/> From spare parts to full-service support, we've got everything you need all conveniently located under one roof.</li>
        <li><strong>Quality Guaranteed:</strong> <br/> Trust us to provide only the best for your precious auto.</li>
        <li> <strong>Driver Community:</strong> <br/> Join our exclusive network of drivers who know where to find the best deals and support.</li>
      </ul>

      <p className="closing-text">
        Become a part of the Eklara Autos family today and see why many drivers trust us to keep their autos in top shape.
        Let’s keep your auto—and your business—running smoothly!
      </p>

      <button className="join-btn">Ready to join us?</button>
    </div>
            </div>
            <div className="py-2">
           
              <ServicePriceList />
            </div>
          
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
);

export default Home;
