import React from 'react';
import './HeaderTop.css'; // Optional: Add styles for the header top

const HeaderTop = () => (
  <div className="header-top d-none">
    <div className="container">
      <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
        <div className="col-auto d-none d-lg-block">
          <div className="header-links">
            <ul>
              <li>Address: 752, JMD Megapolis, Sec-48, Gurugram, Haryana 122001</li>
              <li>Call Link: <a href="tel:6295550129">989-957-4174</a></li>
            </ul>
          </div>
        </div>
        <div className="col-auto">
          <div className="header-links">
            <ul>
              <li>
                <div className="social-links">
                  <span className="social-title">Follow On :</span>
                  <a href="https://www.facebook.com/" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                  <a href="https://x.com/EklaraF84344" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                  <a href="https://www.linkedin.com/" aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
                  <a href="https://www.instagram.com/" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HeaderTop;
