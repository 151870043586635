import React, { useState } from 'react';
import './ServicePriceList.css'; // Import the CSS file

const ServicePriceList = () => {
  const [showMore, setShowMore] = useState(false);

  const services = [
    { type: 'Oil Change', price: '₹1,500', saving: '10%' },
    { type: 'Brake Service', price: '₹2,000', saving: '12%' },
    { type: 'Battery Replacement', price: '₹4,000', saving: '8%' },
    { type: 'Tire Rotation', price: '₹800', saving: '5%' },
    { type: 'Wheel Alignment', price: '₹1,200', saving: '7%' },
    { type: 'AC Service', price: '₹2,500', saving: '12%' },
    { type: 'Car Wash', price: '₹600', saving: '5%' },
    { type: 'Engine Tuning', price: '₹3,500', saving: '11%' },
    { type: 'Suspension Service', price: '₹2,800', saving: '9%' },
    { type: 'Clutch Service', price: '₹3,200', saving: '10%' }
  ];

  return (
    <div>
      <h2 className='text-lg-start text-center pb-3'>Auto Service Price List in Delhi, India (2024)</h2>
      <table className="service-price-table">
        <thead>
          <tr>
            <th>Service Type</th>
            <th>Price (INR)</th>
            <th>Saving (%)</th>
          </tr>
        </thead>
        <tbody>
          {services.slice(0, showMore ? services.length : 4).map((service, index) => (
            <tr key={index}>
              <td>{service.type}</td>
              <td>{service.price}</td>
              <td>{service.saving}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p onClick={() => setShowMore(!showMore)} style={{ color: 'blue', textDecoration: 'underline', textAlign:"end", cursor: 'pointer' }}>
        {showMore ? 'See Less' : 'See More'}
      </p>
    </div>
  );
};

export default ServicePriceList;
