import React, { useState, useEffect } from 'react';
import Swiper from 'swiper';
import './ServiceHighlights.css'; // Import the CSS file
import 'swiper/css';
import 'swiper/css/scrollbar'; // Import Swiper Scrollbar CSS if needed

const features = [
    { id: 'tyres', title: 'Tyres & Wheel Care' },
    { id: 'batteries', title: 'Batteries' },
    { id: 'engine-oil', title: 'Engine Oil' },
    { id: 'accessories', title: 'Accessories' },
    { id: 'suspension', title: 'Suspension & Fitments' },
    { id: 'clutch', title: 'Clutch & Body Parts' },
    { id: 'windshields', title: 'Windshields & Lights' },
    { id: 'denting', title: 'Denting & Painting' },
    { id: 'service-repair', title: 'Service & Repair' }
];

// Sample dummy data for each feature with at least 6 items
const serviceData = {
    'Tyres & Wheel Care': [
        { price: '$100', time: '2 hours', description: 'Service for Tyres & Wheel Care' },
        { price: '$120', time: '2.5 hours', description: 'Advanced Tyre Alignment' },
        { price: '$110', time: '1.5 hours', description: 'Wheel Balancing' },
        { price: '$150', time: '3 hours', description: 'Tyre Replacement' },
        { price: '$80', time: '1 hour', description: 'Tyre Inspection' },
        { price: '$200', time: '2 hours', description: 'Wheel Rim Repair' }
    ],
    'Batteries': [
        { price: '$150', time: '1.5 hours', description: 'Battery Check and Replacement' },
        { price: '$170', time: '2 hours', description: 'Battery Installation' },
        { price: '$160', time: '1 hour', description: 'Battery Maintenance' },
        { price: '$180', time: '2 hours', description: 'Jump Start Service' },
        { price: '$200', time: '3 hours', description: 'Alternator Repair' },
        { price: '$220', time: '2.5 hours', description: 'Battery Cable Replacement' }
    ],
    'Engine Oil': [
        { price: '$120', time: '1 hour', description: 'Oil Change' },
        { price: '$130', time: '1.5 hours', description: 'Oil Filter Replacement' },
        { price: '$110', time: '1 hour', description: 'Synthetic Oil Change' },
        { price: '$140', time: '2 hours', description: 'Full Service Oil Change' },
        { price: '$150', time: '2 hours', description: 'Engine Cleaning' },
        { price: '$160', time: '1.5 hours', description: 'Engine Oil Flush' }
    ],
    'Accessories': [
        { price: '$80', time: '30 mins', description: 'Accessory Installation' },
        { price: '$90', time: '45 mins', description: 'Custom Accessories' },
        { price: '$70', time: '20 mins', description: 'Accessories Check' },
        { price: '$100', time: '1 hour', description: 'Accessory Repair' },
        { price: '$85', time: '35 mins', description: 'Accessory Cleaning' },
        { price: '$95', time: '40 mins', description: 'Accessory Upgrade' }
    ],
    'Suspension & Fitments': [
        { price: '$200', time: '3 hours', description: 'Suspension Inspection' },
        { price: '$220', time: '3.5 hours', description: 'Suspension Repair' },
        { price: '$210', time: '2.5 hours', description: 'Shock Absorber Replacement' },
        { price: '$230', time: '4 hours', description: 'Fitment Services' },
        { price: '$250', time: '5 hours', description: 'Suspension Rebuild' },
        { price: '$240', time: '3 hours', description: 'Steering & Suspension Tuning' }
    ],
    'Clutch & Body Parts': [
        { price: '$180', time: '2.5 hours', description: 'Clutch Inspection' },
        { price: '$190', time: '3 hours', description: 'Clutch Replacement' },
        { price: '$200', time: '3.5 hours', description: 'Body Parts Replacement' },
        { price: '$210', time: '4 hours', description: 'Panel Beating' },
        { price: '$220', time: '4.5 hours', description: 'Frame Alignment' },
        { price: '$230', time: '5 hours', description: 'Full Body Paint' }
    ],
    'Windshields & Lights': [
        { price: '$130', time: '2 hours', description: 'Windshield Repair' },
        { price: '$150', time: '3 hours', description: 'Windshield Replacement' },
        { price: '$140', time: '1.5 hours', description: 'Headlight Restoration' },
        { price: '$160', time: '2.5 hours', description: 'Light Bulb Replacement' },
        { price: '$170', time: '3 hours', description: 'Mirror Replacement' },
        { price: '$180', time: '3.5 hours', description: 'Wiper Blade Replacement' }
    ],
    'Denting & Painting': [
        { price: '$220', time: '5 hours', description: 'Dent Removal' },
        { price: '$240', time: '6 hours', description: 'Full Body Paint' },
        { price: '$230', time: '4.5 hours', description: 'Scratch Repair' },
        { price: '$250', time: '5.5 hours', description: 'Paint Protection Film' },
        { price: '$260', time: '6 hours', description: 'Polishing and Detailing' },
        { price: '$270', time: '6.5 hours', description: 'Ceramic Coating' }
    ],
    'Service & Repair': [
        { price: '$300', time: '6 hours', description: 'Full Car Service' },
        { price: '$320', time: '7 hours', description: 'Brake Service' },
        { price: '$310', time: '6.5 hours', description: 'Transmission Repair' },
        { price: '$330', time: '7.5 hours', description: 'Exhaust System Repair' },
        { price: '$340', time: '8 hours', description: 'Radiator Replacement' },
        { price: '$350', time: '8.5 hours', description: 'Fuel System Service' }
    ]
};

const ServiceHighlights = () => {
    const [activeService, setActiveService] = useState('Tyres & Wheel Care'); // Default active service

    useEffect(() => {
        new Swiper('.swiper-container', {
            slidesPerView: 'auto',
            spaceBetween: 10, 
            freeMode: true,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
        });
    }, []);

    return (
        <div className="service-highlights-container">
            <div className="swiper-container service-highlights">
                <div className="swiper-wrapper">
                    {features.map((feature, index) => (
                        <div
                            className={`swiper-slide ${activeService === feature.title ? 'active' : ''}`} 
                            key={index}
                            onClick={() => setActiveService(feature.title)} // Handle click to set the active service
                        >
                            <div className="swiper-slide-content">
                                <h5>{feature.title}</h5>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="swiper-scrollbar"></div>
            </div>

            {/* Display service data based on the active service */}
            <div className="service-details">
                {serviceData[activeService].map((data, idx) => (
                    <div className="card service-card" key={idx}>
                        <h4>{activeService}</h4>
                        <p>{data.description}</p>
                        <p>Price: {data.price}</p>
                        <p>Time: {data.time}</p>
                        <button className="add-to-cart-btn">Add to Cart</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceHighlights;
