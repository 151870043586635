import React, { useState } from "react";
import "./ContactForm.css"; // Ensure this is correctly linked

// Import images
import bajajLogo from "../../assets/images/bajaj.png";
import piaggioLogo from "../../assets/images/piaggio.png";
import mahindraLogo from "../../assets/images/mahindra.png";
import ycElectricLogo from "../../assets/images/yc.png";
import saeraElectricLogo from "../../assets/images/saeera.png";
import dilliElectricLogo from "../../assets/images/dilli.png";
import atulAutoLogo from "../../assets/images/atul.png";
import tvsAutoLogo from "../../assets/images/tvs.png";
import miniMetroLogo from "../../assets/images/mini.png";
import jsAutoLogo from "../../assets/images/jsauto.png";

const ContactForm = () => {
  const [showManufacturerList, setShowManufacturerList] = useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleNumberError, setVehicleNumberError] = useState(false);

  const manufacturers = [
    { name: "Bajaj Auto", logo: bajajLogo },
    { name: "Piaggio", logo: piaggioLogo },
    { name: "Mahindra & Mahindra", logo: mahindraLogo },
    { name: "YC Electric", logo: ycElectricLogo },
    { name: "Saera Electric Auto", logo: saeraElectricLogo },
    { name: "Dilli Electric Auto (CityLife)", logo: dilliElectricLogo },
    { name: "Atul Auto", logo: atulAutoLogo },
    { name: "TVS Auto", logo: tvsAutoLogo },
    { name: "Mini Metro EV L.L.P", logo: miniMetroLogo },
    { name: "J.S. Auto", logo: jsAutoLogo },
  ];

  const handleManufacturerSelect = (manufacturer) => {
    setSelectedManufacturer(manufacturer.name);
    setShowManufacturerList(false);
  };

  const handleCategoryClick = () => {
    setShowManufacturerList(true);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    // Remove non-numeric characters
    const numericValue = inputValue.replace(/\D/g, "");
    setPhoneNumber(numericValue);

    // Validate if input is empty or not a number
    if (numericValue === "") {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  const handlePhoneNumberBlur = () => {
    if (phoneNumber === "") {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  const handleVehicleNumberChange = (event) => {
    setVehicleNumber(event.target.value);
    setVehicleNumberError(false);
  };

  const handleVehicleNumberBlur = () => {
    if (vehicleNumber === "") {
      setVehicleNumberError(true);
    } else {
      setVehicleNumberError(false);
    }
  };

  // State to hold the selected area value
  const [selectedArea, setSelectedArea] = useState("");

  // Function to handle the change event
  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  return (
    <div className="contact-form-container">
      {showManufacturerList ? (
        <div className="manufacturer-list">
          <button
            className="btn close-list"
            onClick={() => setShowManufacturerList(false)}
          >
            &times;
          </button>
          {manufacturers.map((manufacturer, index) => (
            <div
              key={index}
              className="manufacturer-item"
              onClick={() => handleManufacturerSelect(manufacturer)}
            >
              <img src={manufacturer.logo} alt={manufacturer.name} />
              <span>{manufacturer.name}</span>
            </div>
          ))}
        </div>
      ) : (
        <form action="" method="POST" className="contact-form ajax-contact">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <select
                  id="area"
                  name="area"
                  className="form-control style3"
                  value={selectedArea}
                  onChange={handleAreaChange}
                >
                  <option value="">Select Area</option>
                  <option value="north">North, Delhi</option>
                  <option value="north-east">North-East, Delhi</option>
                  <option value="north-west">North-West, Delhi</option>
                  <option value="west">West, Delhi</option>
                  <option value="south">South, Delhi</option>
                  <option value="south-west">South-West, Delhi</option>
                  <option value="south-east">South-East, Delhi</option>
                  <option value="new-delhi">New Delhi</option>
                  <option value="central">Central, Delhi</option>
                  <option value="shahdara">Shahdara</option>
                  <option value="east">East, Delhi</option>
                </select>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control style3 ${
                    selectedManufacturer === "" ? "input-error" : ""
                  }`}
                  id="vehicle-category"
                  name="vehicle-category"
                  placeholder="Select Manufacturer"
                  onClick={handleCategoryClick}
                  value={selectedManufacturer}
                  readOnly
                />
              </div>
            </div>

            {/* Vehicle Number Field */}
            <div className="col-lg-12">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control style3 ${
                    vehicleNumberError ? "input-error" : ""
                  }`}
                  id="vehicle-number"
                  name="vehicle-number"
                  placeholder={
                    vehicleNumberError ? "⚠ Vehicle Number Required" : "Enter Vehicle Number"
                  }
                  value={vehicleNumber}
                  onChange={handleVehicleNumberChange}
                  onBlur={handleVehicleNumberBlur}
                  required
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control style3 ${
                    phoneNumberError ? "input-error" : ""
                  }`}
                  name="number"
                  id="number"
                  placeholder={
                    phoneNumberError ? "⚠ Phone Number Required" : "Enter Phone Number"
                  }
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  onBlur={handlePhoneNumberBlur}
                  maxLength="10"
                />
              </div>
            </div>
          </div>
          <div className="form-btn col-12 text-center">
            <button type="submit" className="btn w-100 style4">
              Check Prices For Free
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
