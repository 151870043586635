import React from 'react';
import { Link } from 'react-router-dom';
import './FeaturesSection.css';  // Adjust the path if needed
// Import images
import tyreCareImg from '../../assets/images/tyre.png';
import batteriesImg from '../../assets/images/lighting.png';
import engineOilImg from '../../assets/images/engine-oil.png';
import accessoriesImg from '../../assets/images/power.png';
import suspensionImg from '../../assets/images/suspension.png';
import clutchBodyImg from '../../assets/images/clutch-disc.png';
import windshieldsLightsImg from '../../assets/images/windshield.png';
import dentingPaintingImg from '../../assets/images/paint-palette.png';
import serviceRepairImg from '../../assets/images/customer-support.png';

const features = [
    {
        href: '/services', // Updated path
        imgSrc: tyreCareImg,
        alt: 'tyre-care',
        title: 'Tyres & Wheel Care'
    },
    {
        href: '/services', // Updated path
        imgSrc: batteriesImg,
        alt: 'batteries',
        title: 'Batteries'
    },
    {
        href: '/services', // Updated path
        imgSrc: engineOilImg,
        alt: 'engine-oil',
        title: 'Engine Oil'
    },
    {
        href: '/services', // Updated path
        imgSrc: accessoriesImg,
        alt: 'accessories',
        title: 'Accessories'
    },
    {
        href: '/services', // Updated path
        imgSrc: suspensionImg,
        alt: 'suspension',
        title: 'Suspension & Fitments'
    },
    {
        href: '/services', // Updated path
        imgSrc: clutchBodyImg,
        alt: 'clutch-body',
        title: 'Clutch & Body Parts'
    },
    {
        href: '/services', // Updated path
        imgSrc: windshieldsLightsImg,
        alt: 'windshields-lights',
        title: 'Windshields & Lights'
    },
    {
        href: '/services', // Updated path
        imgSrc: dentingPaintingImg,
        alt: 'denting-painting',
        title: 'Denting & Painting'
    },
    {
        href: '/services', // Updated path
        imgSrc: serviceRepairImg,
        alt: 'service-repair',
        title: 'Service & Repair'
    }
];

const FeaturesSection = () => {
    return (
        <div className="content">
            <div className="row row-cols-1 row-cols-md-3 g-4 pb-5 px-md-0 px-3">
                {features.map((feature, index) => (
                    <div className="col" key={index}>
                        <Link to={feature.href} className="features-card style-5 h-100">
                            <div className="icon">
                                <img src={feature.imgSrc} alt={feature.alt} />
                            </div>
                            <div className="info">
                                <h5 className="card-title">
                                    {feature.title}
                                </h5>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeaturesSection;
