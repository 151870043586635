import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './EServicesHelpdesk.css';

const EServicesHelpdesk = () => {
  const navigate = useNavigate(); // Initialize navigate

  const services = [
    'LMV License',
    'PSV Badge',
    'Renewal Permit',
    'Meter Certificate',
    'Insurance Certificate',
    'Fitness Certificate',
    'Pollution Under Control',
    'CNG Cylinder Inspection',
    'Registration Certificate',
    'Insurance Claims'
  ];

  // Function to handle redirection
  const handleCardClick = () => {
    navigate('/services'); // Redirect to services page
  };

  return (
    <section className="eservices-helpdesk text-md-start">
      <h2>Our E-Services Helpdesk</h2>
      <h5 className='caveat-text fw-bold'>Paperwork can be a pain, but not anymore!</h5>
      <p>
        Our E-Services Helpdesk makes it easier to get all your licenses, permits, and certificates sorted, so you can focus on what you do best—driving.
      </p>
      <div className="card-container">
        {services.map((service, index) => (
          <div 
            key={index} 
            className="service-card" 
            onClick={handleCardClick} // Add onClick event to each card
            style={{ cursor: 'pointer' }} // Add cursor style for better UX
          >
            <h3>{service}</h3>
          </div>
        ))}
      </div>
    </section>
  );
}

export default EServicesHelpdesk;
