import React from 'react';
import Header from '../components/common/Header'; // Adjusted path
import Footer from '../components/common/Footer'; // Adjusted path
import './Services.css'; // Add custom styles for the services page
import EServicesHelpdesk from '../components/common/EServicesHelpdesk';
import ServicePriceList from '../components/common/ServicePriceList'; // Adjust the path if necessary
import ContactForm from "../components/common/ContactForm";
import ServiceHighlights from '../components/common/ServiceHighlights'; 

const Services = () => (
  <div className="services-page-container">
    <Header />
    <div className="main-content row"> {/* Bootstrap row */}
      {/* Left Content */}
      <div className="content-left col-lg-8"> {/* Set to col-lg-8 */}
        <ServiceHighlights /> {/* Added sticky component here */}
        <section className="content-section">
          <EServicesHelpdesk />
          <div className="py-5">
            <h2>Our Service Price List</h2>
            <ServicePriceList />
          </div>
          <div className="py-5">
            <h2>Why Choose Eklara's Services?</h2>
            <div className="services-benefits-container">
              <div className="benefit">
                <h3>Quality Assurance</h3>
                <p>We ensure that every service we provide is of the highest quality, tailored to meet your needs.</p>
              </div>
              <div className="benefit">
                <h3>Affordable Pricing</h3>
                <p>Our services are priced competitively, ensuring you get the best value for your money.</p>
              </div>
              <div className="benefit">
                <h3>Customer Support</h3>
                <p>Our team is available 24/7 to assist you with any queries or issues.</p>
              </div>
              <div className="benefit">
                <h3>Comprehensive Coverage</h3>
                <p>From licenses to certifications, we cover all aspects of your auto service needs.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Right Content */}
      <div className="content-right col-lg-4 px-4"> {/* Set to col-lg-4 */}
        <div className="sticky-form">
          <h5 className='text-center'>Contact Us</h5>
          <ContactForm />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default Services;
